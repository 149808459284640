import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellnes',
  templateUrl: './wellnes.component.html',
  styleUrls: ['./wellnes.component.scss']
})
export class WellnesComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/wellnes/slider-wellnes-1.jpg",
    "../../../../assets/articles/wellnes/slider-wellnes-2.jpg",
    "../../../../assets/articles/wellnes/turismomedico_moe.jpg",
    "../../../../assets/articles/wellnes/turismomedico_moe2.jpg"
  ]


  constructor() { }


  ngOnInit(): void {
  }

}

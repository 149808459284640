<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Deportivo</h1>
    <div class="article-main_body">
        <div class="article-main_content">
          <br>
          <br>
          <p>El <b>turismo deportivo</b> es uno de los más populares en nuestro país ¡Y no en vano! Además de la gran relevancia del fútbol masculino, que nos convirtió
            en el último <b>Campeón del Mundo; Argentina</b> está repleta de espacios para la práctica de un sinfín de disciplinas profesionales de alto nivel.
          </p>
            <p>Se trata de un sector de rápido crecimiento dentro de la industria mundial de viajes, y desde <b>MOEBIUS Argentina Incoming DMC</b> diseñamos las mejores
               experiencias para tus clientes: desde vivir eventos y espectáculos como el <b>clásico Boca Vs. River</b> o el <b>Moto GP</b>, hasta experimentar el deporte profesional
               en escuelas de Golf y fútbol que ofrecen la posibilidad de vincularse con <b>clubes de primera línea.</b>
            </p>
            <p>Nuestros <b>paquetes de viaje</b> se adaptan a cada necesidad, ofreciendo toda la <b>logística</b> necesaria para armar itinerarios para los apasionados del deporte
               argentino. Transporte y/o traslados, hospedaje, alimentación y más serán gestionados desde ya por nuestro equipo de <b>especialistas.</b> Contactá ahora mismo
               con un ejecutivo de ventas.
            </p>
        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-luxury',
  templateUrl: './luxury.component.html',
  styleUrls: ['./luxury.component.scss']
})
export class LuxuryComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/luxury/luxury-slider-3.jpg",
    // "../../../../assets/articles/luxury/luxury-header.jpg",
    "../../../../assets/articles/luxury/luxury-slider-2.jpg",
    "../../../../assets/articles/luxury/luxuri_01.jpg",
    "../../../../assets/articles/luxury/luxuri_02.jpg",
    "../../../../assets/articles/luxury/luxuri_03.jpg",
  ]


  constructor() { }

  ngOnInit(): void {
  }

}

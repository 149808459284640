import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';


import { HomeComponent } from './pages/home.component';
import { SelectComponent } from './pages/select/select.component';
import { AppComponent } from './app.component';
import { ArticlesComponent } from './pages/content-articles/articles.component';
import { AboutUsComponent } from './pages/shared/about-us/about-us.component';
import { WellnesComponent } from './pages/content-articles/wellnes/wellnes.component';
import { LuxuryComponent } from './pages/content-articles/luxury/luxury.component';
import { MiceComponent } from './pages/content-articles/mice/mice.component';
import { LeisureComponent } from './pages/content-articles/leisure/leisure.component';
import { CorporativeComponent } from './pages/content-articles/corporative/corporative.component';
import { SportsComponent } from './pages/content-articles/sports/sports.component';


const appRoutes: Routes = [

    {
      path: 'select',
      component: SelectComponent
    },
    // {
    //   path: 'articles',
    //   component: ArticlesComponent
    // },
    {
      path: 'about-us',
      component: AboutUsComponent
    },
    { path: 'leisure',
      component: LeisureComponent
    },
    {
      path: 'wellnes',
      component: WellnesComponent
    },
    {
      path: 'luxury',
      component: LuxuryComponent
    },
    {
      path: 'mice',
      component: MiceComponent
    },
    {
      path: 'sports',
      component: SportsComponent
    },
    {
      path: 'corporative',
      component: CorporativeComponent
    },
    {
      path: 'home/:redirect',
      component: HomeComponent
    },
    {
      path: '',
      redirectTo: 'home/',
      pathMatch: 'full'
    },
   {
      path: '**',
      redirectTo: 'home/',
      pathMatch: 'full'
    }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes // <-- debugging purposes only
, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

import { Component, EventEmitter, Input, Output  } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr/ng2-toastr';
//import { Logger } from 'angular2-logger/core';

import { ResetpassForm } from './resetpass';
import { ResetpassService } from './resetpass.service';
import { setting } from '../../../../setting';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

//  Jquery imports
// import * as jquery from 'jquery';
declare var jquery:any;
declare var $:any;

@Component({
	selector: 'resetpass',
	templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.scss'],
	providers: [ResetpassService]
})

export class ResetpassComponent {
	public resetForm : FormGroup;
	public resetError : ResetpassForm;
	public load : boolean;
  public getClientsSettingAppAuth: any;
  public showModal: boolean = false;
  public titleModal: string = '';
  public message: string = '';
	@Output() finished = new EventEmitter<boolean>();
  @Output() loadResetPass = new EventEmitter<boolean>();

	constructor(
		private _resetpassService: ResetpassService,
    private formBuilder: FormBuilder
	)

  {
    this.resetForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
		this.resetError = new ResetpassForm("");
		this.load = false;
    this.getClientsSettingAppAuth =  JSON.parse((localStorage.getItem(setting.name)));
	}


  onReset() {
    this.loadResetPass.emit(true);
    this.load = true; // pantalla de carga

    const resetEmail = {
                          email: this.resetForm.value.email,
                          client_id: this.getClientsSettingAppAuth.id
                       };

    this._resetpassService.resetpass(resetEmail).subscribe({
        next: (data: any) => {
          this.dataResponse(data);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.load = false;
        }
    });
}

  private dataResponse(data: any) {
      if (data.res == 1) {
        this.dataSucces();
      } else {
        this.dataFalse();
      }
      this.load = false;
  }

  private dataSucces() {
    this.openModal(true);
  }

  private dataFalse() {
    this.loadResetPass.emit(false);
    this.load = false;
    this.openModal(false);
  };

	getFinished() {
		return this.finished;
	}

  openModal(success: boolean) {
    if(success) {
      this.titleModal = 'Cambio de password';
      this.message = `Se ha enviado un email a ${this.resetForm.value.email}`;
      this.showModal = true;
      setTimeout(() => {
          this.finished.emit(true);
      }, 5000);
    } else {
      this.titleModal = 'Advertencia';
      this.message = 'Email no encontrado';
      this.showModal = true;
    }
  }

  closeModal() {
    this.showModal = false;

    if(this.titleModal === 'Cambio de password') {
      this.finished.emit(true);
    }
  }

  confirmAction() {
    this.closeModal();
  }


}

import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';

import { SignupForm } from './signup';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setting } from '../../../../setting';

@Injectable()

export class SignupService {
	public baseUrl:string;

	constructor(private _http:HttpClient){
		this.baseUrl = setting.apiBookingUrl;
	}

	signup(signup: any){
		let json = JSON.stringify(signup);
		let headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		return this._http.post(this.baseUrl+'joinus', json, {headers: headers});
	}



}

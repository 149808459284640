import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leisure',
  templateUrl: './leisure.component.html',
  styleUrls: ['./leisure.component.scss']
})
export class LeisureComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/leisure/leisure-slider-1.jpg",
    "../../../../assets/articles/leisure/leisure-slider-2.jpg",
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { setting } from "../../../../../setting";
import { Subscription } from 'rxjs';


@Component({
  selector: 'usb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {



  menuHidden = true;
  public getClientsSettingAppAuth: any;
  public sectionFooter: any;
  public routeUploadFile:any;
  isHome: boolean = true;
  currentYear: any;
  public wsp_number: string;
  private routeEventsIsHomeSubscription: Subscription;
  constructor(private router: Router) {
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
    this.routeUploadFile =  setting.routeUploadFile;
    this.sectionFooter = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_footer);
    // console.log(this.getClientsSettingAppAuth)
    let wspNumber = this.getClientsSettingAppAuth?.wsp_phone_number;

    // Eliminar el signo + y los espacios en blanco
    wspNumber = wspNumber.replace(/\+/g, '').replace(/\s/g, '');

    this.wsp_number = wspNumber;
/* -----------------  change style and background by path change ---------------------------- */

this.routeEventsIsHomeSubscription = this.router.events.subscribe(event => {
  if (event instanceof NavigationEnd) {
    if (event.url === '/home') {
      this.isHome = true;
    } else if (event.url === '/select') {
      this.isHome = false;
    }
  }
});
  }
  ngOnDestroy(): void {
    this.routeEventsIsHomeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.routeUploadFile =  setting.routeUploadFile;
    this.sectionFooter = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_footer);
    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  onLinkAbout(fragment: string) {
    this.router.navigate(['/about-us'], { fragment }).then(() => {
      window.scrollTo(0, 0);
    });
  }

  onLinkSegment(nameRoute: string) {

    if(nameRoute === 'leisure') {
      this.router.navigate(['/leisure']).then(() => {
        window.scrollTo(0, 0);
      })
    }
    if(nameRoute === 'wellnes') {
      this.router.navigate(['/wellnes']).then(() => {
        window.scrollTo(0, 0);
      })
    }
    if(nameRoute === 'luxury') {
      this.router.navigate(['/luxury']).then(() => {
        window.scrollTo(0, 0);
      })
    }
    if(nameRoute === 'mice') {
      this.router.navigate(['/mice']).then(() => {
        window.scrollTo(0, 0);
      })
    }
    if(nameRoute === 'sports') {
      this.router.navigate(['/sports']).then(() => {
        window.scrollTo(0, 0);
      })
    }
    if(nameRoute === 'corporative') {
      this.router.navigate(['/corporative']).then(() => {
        window.scrollTo(0, 0);
      })
    }


  }

}

<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Corporativo</h1>
    <div class="article-main_body">
        <div class="article-main_content">
          <br>
          <br>
          <p>Los <b>viajes profesionales</b> son la especialidad de nuestro departamento <b>CORPORATIVO</b>. Desde allí, construimos soluciones integradas para agilizar
            el proceso productivo de un segmento más específico que se resume en la <b>concreción de negocios.</b>

          </p>
            <p>El turismo regular se ha convertido en la propuesta ideal para asegurar la <b>optimización del tiempo,</b> factor que determina el éxito del objetivo
               propuesto de manos de los pasajeros corporativos.

            </p>
            <p>Aun así, esto implica separarse de su lugar habitual de trabajo y residencia; requiriendo de una <b>planificación y logística</b> para la cual te
              ofrecemos asesoramiento personalizado de manos de nuestros especialistas.

            </p>
            <p>Esto contempla la creación de <b>paquetes a la medida del itinerario de viaje laboral</b>, abarcando desde los <b>medios de transporte</b> hasta alimentación,
              <b>alojamiento</b> y reserva de sitios acordes para  convenciones, conferencias, eventos o ferias, de acuerdo a las necesidades de tus clientes.

            </p>
            <p>Conoce lo más selecto de nuestro portafolio y ofrece <b>viajes corporativos para organizaciones modernas.</b> No dudes en contactarte con uno de nuestros
              <b>ejecutivos de ventas.</b>


            </p>

        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

import { Router, Params, ActivatedRoute, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";

import { LoginService } from "../login/login.service";
import { environment } from "../../../environments/environment";
import { SelectAgencyModalComponent } from "../select-agency-modal/select-agency-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import CssFilterConverter from "css-filter-converter";
import { ToastrService } from "ngx-toastr";
import { setting } from "../../../../setting";
import { Subscription } from "rxjs";

declare var Raphael: any;

@Component({
  selector: "usb-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [LoginService],
})
export class SelectComponent implements OnInit{
  @ViewChildren("icon", { read: ElementRef }) icons: QueryList<ElementRef>;
  public load: boolean;
  public modules: any;
  public urls: any;
  public activeform: any;
  public user: any;
  public id: any;
  public change_password: any;
  public app: any = {
    booking: false,
    booking_name: "",
    booking_url: "#",
    booking_backoffice: false,
    booking_backoffice_name: "",
    booking_backoffice_url: "#",
    abaco: false,
    abaco_url: "#",
    chat: false,
    chat_url: "#",
  };
  public module_names: any;
  public perfil: any;
  public nameAgency: any;
  modalRef: BsModalRef;
  public agencysUser;
  public data;
  public getClientsSettingAppAuth: any;
  public sectionHub: any;
  public routeUploadFile: any;
  public filter?: any;

  /*--------- Hover icon select app -------------*/
  bookingHover: boolean = true;
  chatHover: boolean = true;
  abacoHover: boolean = true;
  backofficeHover: boolean = true;

  private currentUserSubscription: Subscription;
  private listToModuleSubscription: Subscription;
  private getProfileUserSubscription: Subscription;
  private getInfoAgencySubscription: Subscription;

  constructor(
    private _loginService: LoginService,
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.load = false;
    this.module_names = setting.app_names;
  }


  ngOnInit() {
    this.getClientsSettingAppAuth = JSON.parse(
      localStorage.getItem(setting.name)
    );
    this.sectionHub = JSON.parse(
      this.getClientsSettingAppAuth.clients_setting_app_auth.section_hub
    );
    this.initUser(); // get the user info.
    this.applyImageFilter();
  }

  initUser() {
    this.load = true;
    this.routeUploadFile = setting.routeUploadFile;
    // initializes the user's info.
    let token = this._loginService.getCookie("auth_token");
    if (token) {
      console.log("token", token);
      // a session might exists.
      let data = { auth_token: token };
    this.currentUserSubscription =  this._loginService.getCurrent(data).subscribe(
        (data: any) => {
          console.log(data)
          if (data.res == 1) {
            this.user = data.det;
            this.id = this.user.id;
            this.change_password = this.user.change_password;
            localStorage.setItem("user", this.user);
            let module_data = {
              email: data.det.email,
              auth_token: this._loginService.getCookie("auth_token"),
            };
            this.getProfileUser(module_data);
            this.getModules(module_data);
            this.getInfoAgency(module_data, this.user);
          } else {
            this.load = false;
            this._loginService.setCookie(
              "auth_token",
              "",
              -1,
              setting.c_domain
            ); // deletes cookie
            this._router.navigate(["/home", {}]); // redirects to home with toast
          }
        },
        (error) => {
          this.load = false;
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain); // deletes cookie
          this._router.navigate(["/home", {}]); // redirects to home with toast
        }
      );
    } else {
      // redirects to login
      this.toastr.error("Necesita loguearse primero");
      this._router.navigate(["/home", {}]);
    }
  }

    getModules(_data: any) {
    this.listToModuleSubscription =  this._loginService.listToModulesUser(_data).subscribe({
        next: (data: any) => {
          console.log(data)
          if (data.status == 200) {
            this.modules = data.data["mod"];
            this.urls = data.data["url"];
            this.arrangeDesk(_data);
            // console.log(this.modules)

          } else {
            this.load = false;
          }
        },
       error: (error: Error) => {
          this.load = false;
        }
      });
    }

  getProfileUser(_data: any) {
    this._loginService.getProfileUser(_data).subscribe({
     next: (data: any) => {
        console.log(data)
        this.perfil = data.id;
      },
     error: (error: Error) => {
      console.log(error)
        this.load = false;
      }
    });
  }

  getInfoAgency(_data: any, user: any) {
    this.getInfoAgencySubscription = this._loginService.getInfoAgency(_data, user).subscribe(
      (response: any) => {
        if(response.data.length > 0) {
          this.nameAgency = response.data[0].name;
        }
      },
      (error) => {
        this.load = false;
      }
    );
  }

  arrangeDesk(data: any) {
    for (let i = 0; i < this.modules.length; i++) {
      if (this.modules[i]["name"] === "backoffice.acceso.habilitar") {
        this.app.booking_backoffice = true;
        this.app.booking_backoffice_url = setting.appBookingBackoffice;
        this.app.booking_backoffice_name = setting.app_names.booking_backoffice;
      }

      if (this.modules[i]["name"] === "booking.acceso.habilitar") {
        this.app.booking = true;
        this.app.booking_url = setting.appBookingUrl;
        this.app.booking_name = setting.app_names.booking;
      }
    }
    this.load = false;
  }

  onLogout() {
    let data = {
      email: this.user.email,
      auth_token: this._loginService.getCookie("auth_token"),
    };

    this._loginService.logout(data).subscribe(
      (data: any) => {
        if (data.res == 1) {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          this.toastr.success("Sesión cerrada con éxito");
          this._router.navigate(["/home", {}]);
        } else {
          this._loginService.setCookie("auth_token", "", -1, setting.c_domain);
          this._router.navigate(["/home", {}]);
        }
      },
      (error) => {
        this.toastr.error(JSON.parse(error._body).det, "Error");
      }
    );
  }

  // Aplica un filtro de css al elemento de imagen utilizando el color seteado por cliente.
  applyImageFilter() {
    const color =
      this.getClientsSettingAppAuth?.clients_setting_global?.color_main_3;
    const tempFilterObj = CssFilterConverter.hexToFilter(color, {
      sheen: false,
    });

    //Extraemos el literal en String de la conversión
    const tempFilterStr = Object.keys(tempFilterObj)
      .map((key) => `${key}(${tempFilterObj[key]})`)
      .join(" ");

    // Convierte el color a un objeto de filtro de CSS y extrae los filtros de la respuesta color del resultado.
    const colorRegex = /color\((.*)\)/;
    const colorMatch = tempFilterStr.match(colorRegex);
    const colorFilter = colorMatch ? colorMatch[1] : "";

    this.filter = colorFilter;

    // Establece el atributo de estilo "filter" en el elemento de imagen con los filtros de color extraídos.
    setTimeout(() => {
      this.icons.forEach((icon) => {
        icon.nativeElement.setAttribute("style", `filter: ${this.filter}`);
      });
    }, 100);
  }


  hasAccess(appName: string): boolean {
    if (this.modules) {
      for (let i = 0; i < this.modules.length; i++) {
        const module = this.modules[i];
        if (module.name === appName) {
          return true; // Devolver `true` si el módulo existe
        }
      }

      return false; // Si no se encontró un módulo con el nombre especificado, retornar `false`
    }

    return false; // Si `this.modules` es undefined, retornar `false`
  }

  navigateToApp(url: string) {
    this._router.navigate([url]);
  }

}

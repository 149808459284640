import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/sports/sports-header.jpg",
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

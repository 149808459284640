<!-- Nav tabs <ul class="nav nav-tabs">-->
<ul class="nav nav-pills nav-fill" >
  <li class="nav-item" >
    <a class="nav-link active"  href="#agencies" [ngClass]="{'active': activeBtn === 'agencies'}"
    [ngStyle]="{'color': activeBtn === 'agencies' ? getClientsSettingAppAuth?.clients_setting_global?.color_main_1 : 'black'}"
    (click)="changeActiveBtn('agencies'); changeForm('agencies', $event);" >AGENCIA</a>
  </li>
  <li class="nav-item" >
    <a class="nav-link ml-1 inactive-link" href="#hotels"  [ngClass]="{'active': activeBtn === 'hotels'}"
    [ngStyle]="{'color': activeBtn === 'hotels' ? getClientsSettingAppAuth?.clients_setting_global?.color_main_1 : 'black'}"
    (click)="changeActiveBtn('hotels'); changeForm('hotels', $event);" disabled>HOTEL</a>
  </li>
  <li class="nav-item">
    <a class="nav-link  ml-1 inactive-link"  href="#providers"  [ngClass]="{'active': activeBtn === 'providers'}"
    [ngStyle]="{'color': activeBtn === 'providers' ? getClientsSettingAppAuth?.clients_setting_global?.color_main_1 : 'black'}"
    (click)="changeActiveBtn('providers'); changeForm('providers', $event);">PRESTADOR</a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link  ml-1" data-toggle="tab" href="#chat">CHAT</a>
  </li>
  <li class="nav-item">
    <a class="nav-link  ml-1" data-toggle="tab" href="#tools">ADMIN TOOL</a>
  </li> -->
</ul>

<!-- Tab panes -->
  <div #container>
    <div class="container" *ngIf="activeForm === 'agencies'" id="agencies">

      <agencies-form (successEvent)="onAgenciesFormSuccess()" (errorEvent)="onAgenciesFormError()"></agencies-form>
    </div>
    <div class="container" *ngIf="activeForm === 'hotels'" id="hotels">
      <hotels-form></hotels-form>
    </div>
    <div class="container" *ngIf="activeForm === 'providers'" id="providers">
      <providers-form></providers-form>

    </div>
  <!-- <div class="tab-pane container fade" id="chat">

  	<chat-form></chat-form>

  </div>
    <div class="tab-pane container fade" id="tools">

  	<tools-form></tools-form>

  </div> -->
</div>







<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Wellness</h1>
    <div class="article-main_body">
      <!-- <h2 class="article-main_copy">Bariloche es un destino que brinda todo lo que se necesita para disfrutar de un viaje de ensueño. En esta ciudad de la Patagonia Argentina la diversión está asegurada.</h2> -->
        <div class="article-main_content">
          <br>
          <br>
          <p>Nuestro equipo de profesionales, especialistas en <b>turismo médico</b> ha creado un portfolio con las mejores alternativas para viajes sanadores,
            combinando experiencias relajantes y de crecimiento personal en lugares encantadores y con países inigualables. Nos orientamos a brindar servicios
            interdisciplinarios para alcanzar el bienestar integral con tratamientos de spa y programas de salud.
          </p>
            <p>En <b>Moebius Argentina Incoming DMC</b> trabajamos para diseñar alternativas que generen vivencias saludables y renovadoras para el ser. Nuestra amplia
              gama de servicios se pasean por diversas opciones para mejorar la calidad de vida y lograr el bienestar integral del estado físico, emocional y mental
              de las personas.
            </p>
            <p>De igual forma brindamos experiencias en espacios óptimos para la prevención, el tratamiento y la rehabilitación de distintas afecciones de la salud.
              Centros de salud, termas y spa cuyo objetivo es alcanzar un modo de vida saludable.
            </p>
            <p>Como intermediarios de servicios ofrecemos servicios como:
            </p>
            <ul>
              <li>
                <p><b>● <span class="ml-2">Gestoría:</span></b> requisitos de ingreso al país, certificado de nacimiento, DNI, pasaporte, casamientos, entre otros.
                </p>
              </li>
              <li>
                <p><b>● <span class="ml-2">Traslados:</span></b> con chófer privado hacemos los trayectos del aeropuerto al hotel y a los centros médicos.
                  Después de aterrizar en <b>Argentina</b>, nos encargaremos de recibirlos y acompañarlos al hotel.
                </p>
              </li>
              <li>
                <p><b>● <span class="ml-2">Alojamiento:</span></b> selección de los mejores hoteles 3, 4 y 5 estrellas y departamentos en diferentes zonas de Capital Federal.
                </p>
              </li>
              <li>
                <p><b>● <span class="ml-2">Traductores:</span></b> documentos o intérpretes. </p>
              </li>
              <li>
                <p><b>● <span class="ml-2">Atención al paciente:</span></b> acompañamiento desde el primer contacto con el médico o clínica seleccionada.
                </p>
              </li>
              <li>
                <p><b>● <span class="ml-2">Excursiones y viajes:</span></b> organizamos el pre o post tour para que puedan disfrutar de las mejores experiencias y servicios complementarios.
                </p>
              </li>
            </ul>
            <br>
            <p>Planifica <b>viajes de salud</b> a través de nuestro segmento <b>Wellness</b> ¡Somos especialistas!</p>
        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';

import { environment } from '../../../environments/environment';
import { setting } from '../../../../setting';

@Injectable()

export class LoginService {
	public apiBookingUrl :string;

	constructor(private _http:HttpClient){
		this.apiBookingUrl  = setting.apiBookingUrl
	}


	login(login: any){ // login service
		let headers = new HttpHeaders();
		headers.append('Access-Control-Allow-Origin', '*');
		headers.append('Access-Control-Allow-Credentials', 'true');
		headers.append('Access-Control-Allow-Headers', 'Content-Type');
		headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, DELETE');
		headers.append('Content-Type','application/json');
		headers.append('Authorization','Bearer ' + login.auth_token);
		return this._http.post(this.apiBookingUrl+'login', login, {headers: headers});
	}


	getCurrent(data: any){
		const httpOptions = {
			headers: new HttpHeaders(
				{
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + data.auth_token
				}
			)
		};

		return this._http.get(this.apiBookingUrl+'user/current', httpOptions);
	}

	listModule(data: any) {
		let json = JSON.stringify(data);
		let headers = new HttpHeaders();
		headers.append('Authorization', 'Bearer ' + data.auth_token);
		return this._http.get(this.apiBookingUrl + 'ListUserModule/' + data.email, {headers: headers});

	}



	listToModulesUser(data: any){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + data.auth_token);
		return this._http.get(this.apiBookingUrl + 'application/listToModulesUser', {headers: headers})
	}

	getProfileUser(data: any){
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + data.auth_token);
		return this._http.get(this.apiBookingUrl + 'application/getProfileUser', {headers: headers});
	}

	updAgency(auth_token: any, idAgenncy:any)
	{

		const httpOptions = {
			headers: new HttpHeaders(
				{
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + auth_token
				}
			)
		};

		let json = JSON.stringify(idAgenncy);
		///let headers = new HttpHeaders();
		// headers.append('Content-Type', 'application/json');
		// headers.append('Authorization','Bearer ' + auth_token);
		return this._http.post(this.apiBookingUrl + 'application/updAgency', json, httpOptions);

	}

	getInfoAgency (data: any, idAgenncy: any) {
		let json = JSON.stringify(idAgenncy);
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Authorization', 'Bearer ' + data.auth_token);
		return this._http.post(this.apiBookingUrl + 'application/getInfoAgency', json, {headers: headers});

	}

	getAgency(auth_token: any, data: any){

		const httpOptions = {
			headers: new HttpHeaders(
				{
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + auth_token
				}
			)
		};

		return this._http.get(this.apiBookingUrl + '/agenciesUsers/' + data.user[0].id, httpOptions);
	}

	logout(data: any) {
		let json = JSON.stringify(data);
		let headers = new HttpHeaders();
		headers.append('Content-Type','application/json');
		headers.append('Authorization','Bearer '+data.auth_token);

		return this._http.post(this.apiBookingUrl+'logout', json, {headers: headers});

	}

	validate(token : any) { // validates if the session token is still valid.
		let json = JSON.stringify(token);
		let headers = new HttpHeaders();
		headers.append('Authorization','Bearer '+token.auth_token);
		headers.append('Content-Type','application/json');
		headers.append('Access-Control-Allow-Origin', '*');
		return this._http.post(this.apiBookingUrl+'valid', json, {headers: headers});

	}

	getCookie(name) {
	    var nameEQ = encodeURIComponent(name) + "=";
	    var ca = document.cookie.split(';');
	    for (var i = 0; i < ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) === ' ')
	            c = c.substring(1, c.length);
	        if (c.indexOf(nameEQ) === 0)
	            return decodeURIComponent(c.substring(nameEQ.length, c.length));
	    }
	    return null;
	}

	setCookie(cname, cvalue, exdays, domain) {
		//  exdays = -1,  to delete  cookie.|  exdays = null, to inifinite.
		if (exdays){
		    var d = new Date();
		    d.setTime(d.getTime() + (exdays*24*60*60*1000));
		    var expires = "expires="+ d.toUTCString();
		    if (setting.name == 'local') {
				document.cookie = cname + "=" + cvalue + ";domain="+domain+";path=/"; //nuevo
			} else {
				document.cookie = cname + "=" + cvalue + ";" + expires + ";domain="+domain+";path=/";
			}
		} else {
			if (setting.name == 'local') {
				document.cookie = cname + "=" + cvalue + ";domain="+domain+";path=/"; //nuevo
			} else {
				document.cookie = cname + "=" + cvalue + ";domain="+domain+";path=/";
			}
		}
	}
}

class ResponseCustom
{
	private response;

	constructor(response) {
		this.response = response;
	}

	getResponse() {
		return this.response;
	}

	hasError() {
		return this.response.cod != 200;
	}

	token() {
		return this.response.token;
	}
}

import { SeoService } from './../seoService';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment }    from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home.component';
import { SelectComponent } from './pages/select/select.component';
import { ArticlesComponent } from './pages/content-articles/articles.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetpassComponent } from './pages/resetpass/resetpass.component';
import { SignupComponent } from './pages/signup/signup.component';

import { AgencyComponent } from './pages/signup/agencies/agencies.component';
import { HotelComponent } from './pages/signup/hotels/hotels.component';
import { ProviderComponent } from './pages/signup/providers/providers.component';
import { ChatComponent } from './pages/signup/chat/chat.component';
import { ToolComponent } from './pages/signup/tools/tools.component';
import { HeaderComponent } from './pages/shell/header/header.component';
import { FooterComponent } from './pages/shell/footer/footer.component';
import { SelectAgencyModalComponent } from './pages/select-agency-modal/select-agency-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpConfigInterceptor } from './interceptor/http-config.interceptor';
import { LoginService } from './pages/login/login.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChangepassComponent } from './pages/changepass/changepass.component';
import { AboutUsComponent } from './pages/shared/about-us/about-us.component';
import { WellnesComponent } from './pages/content-articles/wellnes/wellnes.component';
import { LuxuryComponent } from './pages/content-articles/luxury/luxury.component';
import { MiceComponent } from './pages/content-articles/mice/mice.component';
import { LeisureComponent } from './pages/content-articles/leisure/leisure.component';
import { CorporativeComponent } from './pages/content-articles/corporative/corporative.component';
import { SportsComponent } from './pages/content-articles/sports/sports.component';

// import { setting }    from 'setting';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ResetpassComponent,
    SignupComponent,
    AgencyComponent,
    HotelComponent,
    ProviderComponent,
    ChatComponent,
    ToolComponent,
    SelectComponent,
    ArticlesComponent,
    HeaderComponent,
    FooterComponent,
    SelectAgencyModalComponent,
    ChangepassComponent,
    AboutUsComponent,
    WellnesComponent,
    LuxuryComponent,
    MiceComponent,
    LeisureComponent,
    CorporativeComponent,
    SportsComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    CarouselModule,
    NgxPaginationModule

  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    SeoService,
    Title,
    LoginService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
    //this.logger.level = environment.logger;
  }
}

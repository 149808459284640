<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Mice y grupos</h1>
    <div class="article-main_body">
      <!-- <h2 class="article-main_copy">Bariloche es un destino que brinda todo lo que se necesita para disfrutar de un viaje de ensueño. En esta ciudad de la Patagonia Argentina la diversión está asegurada.</h2> -->
        <div class="article-main_content">
          <br>
          <br>
          <p>En <b>MOEBIUS Argentina Incoming DMC</b> diseñamos experiencias personalizadas para la realización de actividades profesionales para empresas.
            Somos especialistas en el armado de paquetes para <b>grupos de trabajo</b>, bien sea <b>por incentivos, conferencias, exhibiciones, ferias, jornadas y/o seminarios.</b>
          </p>
            <p>Sabemos que son muchos los <b>turistas que viajan por negocios</b>, por lo que organizamos <b>eventos laborales</b> a través de los siguientes servicios:
            </p>
            <br>
            <ul>
              <li>
                  <p><b>●</b><span class="ml-2">Reuniones internacionales, conferencias y programas de incentivos.</span>
                </p>
              </li>
              <li>
                  <p><b>●</b><span class="ml-2">Logística y negociaciones de hoteles en todo el país. </span>
                </p>
              </li>
              <li>
                  <p><b>●</b> <span class="ml-2">Asesoría para la gestión y operación de eventos.</span>
              </li>
              <li>
                  <p><b>●</b> <span class="ml-2">Selección de sitios y espacios de encuentro.</span>
                </p>
              </li>
              <li>
                  <p><b>●</b><span class="ml-2">Gestión y reservas de transporte terrestre y aéreo.</span>
                </p>
              </li>
              <li>
                <p><b>●</b> <span class="ml-2">Gestión y reservas de alojamiento.</span>
              </p>
            </li>
            <li>
                <p><b>●</b><span class="ml-2">Experiencias temáticas.</span>
              </p>
            </li>
            <li>
                <p><b>●</b><span class="ml-2">Servicio al huésped.</span>
            </li>
            <li>
                <p><b>●</b><span class="ml-2">Guías locales.</span>
              </p>
            </li>
            <li>
                <p><b>●</b><span class="ml-2">Coordinación permanente.</span>
              </p>
            </li>
            </ul>
            <br>
            <p>Esto incluye todo tipo de viajes, de acuerdo a los <b>grupos</b> y su objetivo; abarcando salidas de placer no solo en caso de <b>incentivos.</b>
              Y es que, los asistentes a <b>congresos y ferias</b> no dudan en aprovechar su tiempo libre para conocer el destino: su historia, cultura,
              gastronomía y costumbres.

            </p>
            <p><b>MICE y GRUPOS</b> se caracteriza por promover el <b>turismo dinamizador;</b> llegando a integrar agentes de diferentes sectores como: agencias de viajes,
              hoteles, restaurantes y empresas de transporte. Todo esto, como parte de una <b>logística</b> que llevamos a cabo desde <b>MOEBIUS Argentina Incoming DMC.</b>
            </p>

            <p>Conoce todo lo que puedes ofrecer en <b>viajes de incentivo, reuniones y eventos de negocios</b> a través de nuestro segmento <b>MICE y GRUPOS</b> ¡Somos especialistas!
            </p>
        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

<form  [formGroup]="resetForm"  (ngSubmit)=" onReset();">
	<div *ngIf="!load">

		<div class="row pb-2">
				<div class="col-12 col-md-5">
					<input class="form-control" placeholder="e.g: calrissian@domain.com" type="email" email name="resetEmail" formControlName="email" required>
					<p class="pt-3 font-parrafo">Introduce la dirección de correo con la que te registraste y te enviaremos los datos de acceso.</p>
				</div>
				<div class="col-12 col-md-3  pl-0">
					<input class="btn-button s-l" style="width: 80% !important;"  type="submit" value="ENVIAR" [disabled]="!resetForm.valid" >
					<!-- <p class="s-sm white" *ngIf="recover_msg"> {{recover_msg}}  </p> -->
				</div>
				<div class="col-12 col-md-1 ">
					<p class="separator-point-green" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"> </p>
				</div>
				<div class="col-12 col-md-3 text-right ">
					<input class="btn-green s-l"  type="submit" value="REGISTRATE" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"
            style="color: #000000 !important; border:none" #modalJoinus data-toggle="modal" data-target="#modalJoinus">
					<p class="pt-2 font-parrafo-grenn" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}">Si no estás registrado</p>
				</div>

        <div>
          <!-- <p class="mb-0"><&nbsp;Atras</p> -->
        </div>

				<!-- ERRORS -->
					<!-- <div *ngIf="resetEmail.invalid && (resetEmail.dirty || resetEmail.touched)">
						<span class="s-sm booking pl-3" *ngIf="r.errors.required">
							Por favor introduzca un email.
						</span>
						<span class="s-sm booking pl-3" *ngIf="r.errors.email">
							Por favor introduzca un email válido.
						</span>
				</div> -->

		</div>

	</div>
  <div *ngIf="load">
    <div class="d-flex justify-content-center align-items-center" style="margin-top: 5%;">
      <div class="spinner-border spinner-border-sm text-white mr-2" role="status">

      </div>
      <!-- <p class="s-m"> Cargando... </p> -->
      <p class="text-white mb-0 font-weight-bold"> CARGANDO... </p>
    </div>
  </div>
</form>


<!-- Template del componente -->
<div class="modal" [ngClass]="{ 'show': showModal }">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Encabezado del modal -->
      <div class="modal-header">
        <h5 class="modal-title mt-2 text-black">{{titleModal}}</h5>
        <!-- <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <!-- Cuerpo del modal -->
      <div class="modal-body ">
        <p class="mb-1 text-black">{{message}}</p>
      </div>
      <!-- Pie del modal -->
      <div class="d-flex flex-end justify-content-end mr-3 mb-4">
        <!-- <button type="button" class="btn btn-secondary" (click)="closeModal()">Cerrar</button> -->
        <button type="button"
                class="btn-confirm-modal rounded-pill"
                [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_1}"
                (click)="confirmAction()">ACEPTAR
        </button>
      </div>
    </div>
  </div>
</div>

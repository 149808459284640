import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporative',
  templateUrl: './corporative.component.html',
  styleUrls: ['./corporative.component.scss']
})
export class CorporativeComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/corporative/corporative-slider-1.jpg",
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Luxury</h1>
    <div class="article-main_body">
      <!-- <h2 class="article-main_copy">Bariloche es un destino que brinda todo lo que se necesita para disfrutar de un viaje de ensueño. En esta ciudad de la Patagonia Argentina la diversión está asegurada.</h2> -->
        <div class="article-main_content">
          <br>
          <br>
          <p>En <b>Moebius Argentina Incoming DMC</b>, promovemos productos de alta gama vinculados a experiencias extraordinarias. Trabajamos en
            conjunto con prestadores orientados a la atención del viajero exigente, en el armado de paquetes más exclusivos para los <b>viajes de lujo</b>,
            que aseguran experiencias exóticas, con clase y distinción.

          </p>
            <p>Estamos convencidos de la magia que tiene el placer de viajar, por eso nos especializamos en crear experiencias para enriquecer sus vidas con
              apasionantes recorridos por el mundo.

            </p>
            <p>Nuestros itinerarios conjugan espacios icónicos y lugares que sólo los locales conocen. Bien sea cruceros, hospedajes en hoteles 5 estrellas,
              viajes en avioneta, combinados, excursiones privadas y mucho más, es lo que nuestro equipo de profesionales del departamento <b>Luxury</b> tiene en el
              portfolio para crear experiencias inolvidables.

            </p>
            <p>Conoce nuestras propuestas contactando a nuestros <b>ejecutivos de ventas.</b>
            </p>

        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<section class="article-banner1">
</section>

<article class="article-main">
  <h1 class="article-main_title" #valores id="valores">Quiénes somos</h1>
  <div class="article-main_body">
    <h2 class="article-main_copy" style="text-align: center; font-size: 22px !important"><b>Moebiustur.ar - Argentina</b>
    </h2>
    <br>
    <div  class="article-main_content">
      <p style="text-align: center"><b>Somos una agencia de viajes mayorista, con más de 15 años de experiencia en turismo receptivo ¡Garantizamos vivencias inolvidables a tus pasajeros!</b>
      </p>
      <br>
        <p ><b>¿Cómo te ayudamos?</b>
          <br>
        </p>
        <p >Contamos con un equipo de profesionales especialistas en el rubro, dispuestos a brindarte toda la <b>asesoría</b> que requieras para proporcionar
          a tus clientes las mejores aventuras turísticas en territorio argentino y LATAM.

        </p>
        <br>
        <p #equipo id="equipo">Te ofrecemos variedad de <b>paquetes turísticos para todos los destinos;</b> armando propuestas imperdibles en base a cada temporada del año:
           alta, media y baja. Esto incluye: traslados, hospedaje y alimentos según tus requerimientos.

       </p>
       <br>
       <p>Para ello, trabajamos a través de varios <b>segmentos de negocios:</b> </p>
       <br>
       <ul>
        <li><b>Diseño y organización integral del viaje:</b> creamos viajes a la medida, de lujo y Lgbtq+. </li>
        <li><b>Viajes de placer:</b> trabajamos con FIT, grupos, turismo de aventura, viajes culturales, deportivos,
          pro & post tour, conciertos, programas para parejas; nieve, naturaleza y bienestar; además de Fly & Drive.</li>
        <li><b>Reunión y viajes de incentivos:</b> dirigido a gestión integral 360°.</li>
        <li><b>Experiencias:</b> para quienes quieren vivir tours y experiencias memorables.</li>
       </ul>
       <p>RTodo esto a través de nuestra plataforma online, desde donde además podés acceder a toda la información con tu respectivo usuario y clave.
        Somos www.moebiustur.ar y estamos operativos 24/7.
      </p>
      <br>
      <br>
      <p  ><b>Nuestra Visión</b></p>
      <br>
      <p>
        Ser en los próximos 5 años el mejor <b>tour operador receptivo</b> en proveer un amplio abanico de productos y un servicio de alta calidad para
        <b>Argentina y LATAM,</b> a operadores globales en las categorías de: pequeñas y medianas empresas.

      </p>
    </div>
  </div>
</article>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

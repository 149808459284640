import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { SignupForm } from './signup';
import { SignupService } from './signup.service';
import { setting } from '../../../../setting';
import { AgencyComponent } from './agencies/agencies.component';
//import { Logger } from 'angular2-logger/core';

//  Jquery imports
// import * as jquery from 'jquery';
declare var jquery:any;
declare var $:any;

@Component({
	selector: 'signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
	providers: [SignupService]
})

export class SignupComponent {
  @ViewChild(AgencyComponent) agenciesFormComponent: AgencyComponent;
  @ViewChild('container') container: ElementRef;
  @Output() successEvent = new EventEmitter<void>();
  @Output() errorEvent = new EventEmitter<void>();
  public getClientsSettingAppAuth: any;
  activeBtn: string = 'agencies';
  activeForm: string = 'agencies';

	constructor(
		private _signupService: SignupService,
    	//private logger : Logger,
    	private fb : FormBuilder,
      private el: ElementRef
	){
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
	}

  changeActiveBtn(btn: string) {
    this.activeBtn = btn;
  }

  /**

changeForm es una función que permite cambiar el formulario activo y desplazar el contenedor hasta su inicio.
@param form - Representa el nombre del formulario a activar.
@param event - Representa un evento opcional, en caso de ser enviado, es previsto su comportamiento por defecto.
*/


  changeForm(form: string, event?: Event) {
    if (event) {
      event.preventDefault();
    }
    if (this.activeForm === form) {
      return;
    }
    this.activeForm = form;
    if (this.container && this.container.nativeElement) {
      const currentPos = this.container.nativeElement.scrollTop;
      if (currentPos !== this.container.nativeElement.offsetTop) {
        this.container.nativeElement.scrollTop = this.container.nativeElement.offsetTop;
      }
    }
  }

  onAgenciesFormSuccess() {
    this.successEvent.emit();

  }

  onAgenciesFormError() {
    this.errorEvent.emit();
  }

}

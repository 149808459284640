import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangepassService } from './changepass.service';
import { LoginService } from '../login/login.service';
import { setting } from '../../../../setting';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.scss']
})
export class ChangepassComponent implements OnInit {
  @Input() id: number;

  @Output() fadeOutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  getClientsSettingAppAuth: any;

  public mismatch: boolean = false;
  public user: any;
  public message: string;
  public messageChangePass: boolean = false;
  public formChangePass: boolean = true;
  public changePassUpdated: boolean = false;

  showPassword: boolean = false;
  showPasswordConfirm: boolean = false;

  changePassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private changePassService: ChangepassService,
  ) { }

  ngOnInit(): void {
    this.getClientsSettingAppAuth = JSON.parse(localStorage.getItem(setting.name));
    this.changePassForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    })
  }


togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}

togglePasswordConfirmVisibility() {
  this.showPasswordConfirm = !this.showPasswordConfirm;
}


  onClick() {
    const button = document.querySelector('.btn-send');
    if (button) {
      button.classList.add('clicked');
      setTimeout(() => {
        button.classList.remove('clicked');
      }, 1000);
    }
  }

  onSubmit() {
    if (this.changePassForm.valid) {
      const password = this.changePassForm.get('password').value;
      const confirmPassword = this.changePassForm.get('confirmPassword').value;

      if (password === confirmPassword) {
        // Las contraseñas coinciden, procede con el envío del formulario
        const payload = {
          id: this.id,
          password_new: this.changePassForm.value.password,
          client_id: this.getClientsSettingAppAuth.id
        }
        console.log(payload);

        this.flagEventOpen();

        this.changePassService.resetPassword(payload).subscribe((res: any) => {
          console.log(res.det);
          if(res.cod = 200) {
            this.flagEventClose(true, res.det);
          } else {
            this.flagEventClose(false)
          }
          // this.onLogout();
          console.log(this.message)
        });
      } else {
        // Las contraseñas no coinciden, muestra el mensaje de error
        this.mismatch = true;
      }
    }
  }

  closeChangePass() {
    this.closeModal.emit();
  }

  private flagEventOpen() {
    this.formChangePass = false;
    this.changePassUpdated = true;
  }

  private flagEventClose(status: boolean, message?: string) {
    if(status) {
      this.flagEvent();
      this.message = message;
    } else {
      this.flagEvent();
      this.message = 'Ha ocurrido un error, vuelva a intentarlo en un momento';
    }
    this.fadeOutEvent.emit();
  }

  private flagEvent() {
    this.changePassUpdated = false;
    this.messageChangePass = true;
  }


}

export const setting= {
  name: "Moebius",
  production: false,
  c_domain: '.moebius.tur.ar',
  apiBTIUrl: 'https://auth-api.usblick.com/api/',
  appUrl: 'https://moebius.tur.ar/#/home/', 
  authApiUrl : 'https://auth-api.usblick.com/api/',
  appBookingBackoffice: 'http://booking-bo.moebius.tur.ar',
  appBookingUrl: 'https://booking.moebius.tur.ar/',
  apiBookingUrl: 'https://booking-api.moebius.tur.ar/api/v1/',
  apiOctopusUrl: 'https://octopus-apis.com/api',
  routeUploadFile: 'https://auth-api.usblick.com/storage/app/public/',
  apiStorageBooking: 'https://auth-api.usblick.com/storage/app/public',
  octopusUrl: 'https://test.octopus-apis.com/api',
  app_names: {
  	booking_backoffice: 'backoffice',
  	zoco: 'zoco',
  	booking: 'booking',
  	abaco: 'abaco',
  	chat: 'chat',
  	octopus: 'octopus'
  }
};
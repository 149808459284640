<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>

<div>
  <section  class="article-banner">
  </section>

  <article class="article-main" >
    <h1 class="article-main_title">Leisure</h1>
    <div class="article-main_body">
        <div class="article-main_content">
          <br>
          <br>
          <p>Nuestro departamento <b>LEISURE</b> cuenta con un equipo altamente capacitado y comprometido a garantizar las más innovadoras propuestas
            turísticas para <b>viajes familiares</b>, en los que prevalece la seguridad, confort y actividades recreativas en las que puedan participar
            cada miembro del grupo que viaja.
          </p>
            <p>En <b>Moebius Argentina Incoming DMC</b>, contamos con una recopilación amplia de los mejores destinos para organizar un <b>viaje en familia con niños</b>,
              con una amplia selección de hoteles de distintas categorías, paseos, restaurantes y aventuras aptas para grandes y chicos.
            </p>
            <p>Creamos paquetes e itinerarios adaptados a las necesidades de los viajeros. Y si es la exigencia, personalizamos la experiencia para que cada viaje sea inolvidable.

            </p>
            <p>Conoce nuestro portfolio contactando a nuestros <b>ejecutivos de ventas.</b>
            </p>

        </div>
    </div>
  </article>
  </div>


<!---------------------------------------  GALERIA  --------------------------------------------->

  <section class="container" style="margin-top: 70px;">
    <h1 class="section-article_title">Galería</h1>
    <!-- <div class="article-galery">
      <img src="./assets/img/articles/articles-galery.jpg">
    </div> -->

    <div class="d-flex flex-column justify-content-center">

      <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

      <div class="d-flex justify-content-end" style="margin-top: 22px">
        <pagination-controls previousLabel=""
        nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div>

  </section>


<div class="section-footer">
  <usb-footer></usb-footer>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mice',
  templateUrl: './mice.component.html',
  styleUrls: ['./mice.component.scss']
})
export class MiceComponent implements OnInit {

  p: number = 1;
  images = [
    "../../../../assets/articles/mice/mice-header.jpg",
    "../../../../assets/articles/mice/mice.jpg",
    "../../../../assets/articles/mice/mice2.jpg",
    "../../../../assets/articles/mice/mice3.jpg",

  ]


  constructor() { }

  ngOnInit(): void {
  }

}
